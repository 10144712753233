import { Flex, useColorMode } from '@chakra-ui/react'
import * as React from 'react'

export interface LayoutRootProps {
  pageTitle?: string
  isDashboard?: boolean
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children }) => {
  const { colorMode } = useColorMode()

  const backgroundColor = React.useMemo(() => {
    if (colorMode === 'dark') {
      return 'gray.900'
    }

    return 'gray.50'
  }, [colorMode])

  return (
    <Flex as="main" flexDirection="column" minHeight="100vh" overflowX="hidden" backgroundColor={backgroundColor}>
      {children}
    </Flex>
  )
}

export default LayoutRoot
