import React from 'react'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import themeLight from 'prism-react-renderer/themes/vsLight'
import themeDark from 'prism-react-renderer/themes/vsDark'
import { Box, Button, Stack, useClipboard, useColorMode } from '@chakra-ui/react'

interface SyntaxHighlighterProps {
  code: string
  language: Language
}

const SyntaxHighlighter: React.FC<SyntaxHighlighterProps> = ({ code, language }) => {
  const { colorMode } = useColorMode()
  const { hasCopied, onCopy } = useClipboard(code)

  return (
    <Box position="relative" borderRadius="lg" overflow="hidden">
      <Highlight {...defaultProps} theme={colorMode === 'dark' ? themeDark : themeLight} code={code} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Box as="pre" className={className} style={style} whiteSpace="pre-wrap">
            {tokens.map((line, i) => (
              <Box display="table-row" key={i} {...getLineProps({ line, key: i })}>
                <Box
                  as="span"
                  display="table-cell"
                  textAlign="right"
                  paddingRight="1em"
                  paddingLeft="0.5em"
                  userSelect="none"
                  opacity="0.5"
                >
                  {i + 1}
                </Box>
                <Box as="span" display="table-cell">
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Highlight>
      <Stack position="absolute" top="1em" zIndex="1" right="1.25em">
        <Button size="sm" textTransform="uppercase" colorScheme="teal" fontSize="xs" height="24px" onClick={onCopy}>
          {hasCopied ? 'Copied' : 'Copy'}
        </Button>
      </Stack>
    </Box>
  )
}

export default SyntaxHighlighter
