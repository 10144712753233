import { Flex, FlexProps } from '@chakra-ui/react'
import * as React from 'react'

export type ContentProps = FlexProps

const Content: React.FC<ContentProps> = ({ children, ...rest }) => {
  return (
    <Flex flex="1 1 auto" pt="64px" {...rest}>
      {children}
    </Flex>
  )
}

export default Content
