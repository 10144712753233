import React from 'react'
import { Box } from '@chakra-ui/react'
import { Container, Content, LayoutRoot, Navigation } from '~/components/layout'
import { SyntaxHighlighter } from '~/components/cards'

export default function Home() {
  const exampleCode = `
import React, { useState } from "react";
function Example() {
  const [count, setCount] = useState(0);
  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}
`.trim()
  return (
    <LayoutRoot>
      <Navigation />
      <Container>
        <Content>
          <Box px={6} pt={8} pb={12}>
            <SyntaxHighlighter code={exampleCode} language="jsx" />
          </Box>
        </Content>
      </Container>
    </LayoutRoot>
  )
}
